.cardHolder{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 10px;
}

#categoriesHolder{
    display: flex;
    flex-direction: column;
}

.ItemTitles{
    border-bottom: 2px solid gold;
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.categoryContainer{
    display: flex;
    flex-direction: column;
}

#searchbutton input{
    outline: 0;
    height: 50px;
    width: calc(100% - 100px);
    box-shadow: none;
    padding: 0;
    margin: 0;
    background-color: #1d3251;
    border-style: none;
    color: white;
    font-size: 1.2rem;
    border-radius: 0;
    border: none;
}

.searchIcon{
    height: 50px;
    width: 50px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1d3251;
}

.emptySearch{
    height: 50px;
    width: 50px;  
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1d3251;
}

.orderbutton{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 130px!important;
}

.loadingContainer,
.notFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust as necessary */
    margin-top: 35px;
}

.loadingImage,
.notFoundImage {
    max-width: 70%;
}

.loadingContainer{
    width: 100%;
}

.notFoundContainer img{
    width: 100%;
    max-width: 60%;
    padding: 20px;
}
.notFoundContainer{
    flex-direction: column;
}

/* Base style for search button container */
#searchbutton {
    display: flex;
    justify-content: center;
    align-items: center;    
    max-width: 800px;
    width: 100%;
    margin: 20px 10px 0 10px;
    position: sticky;
    top: 110px;
    z-index: 1000; /* Ensure it stays above other content */
    opacity: 0;
    transform: translateY(-20px)!important; /* Move it out of view */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Adjust timing as needed */
    margin-top: -30px;
}

/* Style when search button is visible */
.show {
    opacity: 1!important;
    transform: translateY(0)!important; /* Move it into view */
    margin-top: 40px!important;
}

/* Style when search button is hidden */
.hide {
    opacity: 0!important;
    margin-top: -30px!important;
    transform: translateY(-20px)!important; /* Move it out of view */
}

.errorContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}