.card_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 780px;
  padding: 20px 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 1px dashed #333;
  background-color: white;
}

.card_body:last-child {
  border-bottom: 0;
}

.card_img {
  overflow: hidden;
  min-width: 8rem;
  min-height: 5rem;
}

.card_img img {
  position: relative;
  top: 0;
  width: 100%;
  object-fit: cover;
  width: 12rem;
  height: 10rem;
  border-radius: 7px;
}

.card_info {
  width: 100%;
  padding: 5px 20px;
  min-height: 180px;
}

.item_name {
  font-weight: bold;
  font-size: 1.3rem;
}

.item_price_and_serves {
  display: flex;
  flex-direction: row;
}

.item_price {
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-weight: bold;
  flex-grow: 1;
}

.item_serves {
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-weight: bold;
  flex-grow: 1;
  text-align: right;
}

.additem {
  background-color: rgb(228, 194, 0);
  min-width: 100px;
  height: 30px;
  margin: 10px 0;
  border-radius: 3px;
  border: 0;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

.additemskeleton {
  height: 30px;
  margin: 10px 0;
  border-radius: 3px;
  border: 0;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

.nonvegimg img {
  margin-left: -3px;
  width: 1em;
}

.additem:hover {
  background-color: rgb(218, 186, 7);
}

.viewCart {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .card_img img {
    width: 15rem;
    height: 13rem;
  }
}

@media only screen and (max-width: 450px) {
  .card_img img {
    width: 12rem;
    height: 15rem;
  }
}

#addText {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#addMoreText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#cartDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f4f5f6;
  margin: 20px;
  width: 100%;
  max-width: 400px;
  font-size: 0.95rem;
}

#cartItemDetails {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 9px;
}

#cartItemDetails div {
  display: flex;
}

#cartItemDetails img {
  width: 10px;
  height: 10px;
  margin: 10px;
}

#cartProducts {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

#billDetails,
#billDetailsCheckout {
  display: flex;
  justify-content: center;
  padding: 10px 19px;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

#billDetails div {
  padding: 0 0 8px 0;
}

#couponCodeContainer {
  padding: 5px 0 0 20px;
  width: 100%;
  border-top: 1px solid #c8c8c8;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
}

#couponQuestion {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

#coupon_code {
  display: flex;
  flex-grow: 1;
}

#couponInputContainer {
  display: flex;
  padding-right: 10vw;
  padding-bottom: 5px;
}

#couponCodeSubmitButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.cartItemName {
  width: 8rem;
  display: flex;
  justify-content: flex-start;
}

.cartItemImg {
  align-items: flex-start;
  height: 100%;
}

button.additem.cartadditem {
  transform: scale(0.8);
}

#cartItemPrice {
  margin-left: 2rem;
}

#billHeader {
  font-weight: 600;
  font-size: 1.01rem;
  padding: 0 0 12px 0 !important;
  border-top: 1px solid #c8c8c8;
  padding-top: 15px !important;
}

#billData {
  border-bottom: 1px solid #c8c8c8;
}

#billData div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.priceText {
  font-size: 0.8rem;
}

#fetching {
  margin-left: 9px;
}

.totalPay {
  font-weight: bold;
}

#totalBill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px !important;
}

.serve_info {
  margin-top: 10px;
}

#paymentOptions {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  border-top: 1px solid #c8c8c8;
}

.mobile-options,
.desktop-options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mobile-options button,
.desktop-options button {
  padding: 10px;
  margin: 5px 0;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Pathway Extreme", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  box-sizing: border-box;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-order {
  background-color: #25D366;
  /* WhatsApp green */
}

.swiggy-order {
  background-color: #fc8019;
  /* Swiggy orange */
}

.zomato-order {
  background-color: #7e2828;
  /* Zomato red */
}

.zomato-order img {
  border: 2px solid white;
  border-radius: 50%;
}

.swiggy-order img {
  border: 2px solid white;
  border-radius: 50%;
}

.pay-online {
  background-color: #007BFF;
  /* Blue */
}

.pay-online img {
  background-color: white;
  border-radius: 50%;
}

.desktop-options {
  display: none;
}

.payImages {
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.payImages img {
  width: 100%;
}

.mobile-options button span,
.desktop-options button span {
  margin: 0 5px;
}

@media (min-width: 768px) {
  .mobile-options {
    display: none;
  }

  .desktop-options {
    display: flex;
    justify-content: center;
  }
}